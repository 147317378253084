import { AfterViewInit, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Component } from '@angular/core';
import { GoogleMap, MapCircle, MapDirectionsRenderer, MapDirectionsService, MapInfoWindow, MapMarker, MapPolygon, MapPolyline } from '@angular/google-maps';
import { map, Observable } from 'rxjs';
import { VeiculoRastreadoModel } from '../../model/veiculo-rastreado-model';
import { GoogleMapApiService } from '../../service/google-map-api.service';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../service/loading.service';

@Component({
  selector: 'app-google-maps',
  templateUrl: './app-google-maps.component.html',
  styleUrls: ['./app-google-maps.component.scss']
})
export class AppGoogleMapsComponent implements OnInit, AfterViewInit {

  markerClustererImagePath = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';

  /***
   * https://github.com/angular/components/blob/main/src/google-maps/README.md
   * https://developers.google.com/maps/documentation/javascript/examples/directions-draggable
   *
   * Para referencias futuras
   * https://stackoverflow.com/questions/11632402/removing-polygon-from-google-maps-drawingmanager-v3
   *
  */

  mapMarkers: MapMarker[] = [];

  @Input()
  mapMarkerCluster: boolean = true;

  @ViewChildren('markerElem') markers: QueryList<MapMarker>;

  @ViewChild(GoogleMap) map: GoogleMap;

  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  @ViewChild('geometryInfoWindow', { static: false,  }) geometryInfoWindow: any;

  @ViewChild(MapDirectionsRenderer) directionsRenders: MapDirectionsRenderer;

  apiLoaded: Observable<boolean>;

  options: google.maps.MapOptions = {
    center: {lat: -18.729486, lng: -46.477844}
  };

  zoom: number = 5;

  @Input()
  markerOptions: google.maps.MarkerOptions = {draggable: false};

  drawingManager: google.maps.drawing.DrawingManager;

  @Output()
  polygonCreatedEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  circleCreatedEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  polylineCreatedEvent: EventEmitter<any> = new EventEmitter<any>();

  directionsResults$: Observable<google.maps.DirectionsResult|undefined>;

  directionsRendererOptions: google.maps.DirectionsRendererOptions = {
    draggable: true,
  };

  @Output()
  rotaGerada: EventEmitter<google.maps.DirectionsResult | null> = new EventEmitter<google.maps.DirectionsResult | null>();

  @Output()
  apiLoadedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  mapComponentLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() vertices: google.maps.LatLngLiteral[]=[];

  @Input() verticesList: {vertices: google.maps.LatLngLiteral[], polylineOptions: google.maps.PolylineOptions}[]=[];

  markerPositions: {lat: number, lng:number, markerOptions?: google.maps.MarkerOptions, dataObject?: any}[] = [];

  markerDataList: VeiculoRastreadoModel[] = [];

  dadosMarker: {placa: string, velocidade: number, ign: boolean, lat: number, lng:number } = {placa: '', velocidade: 0, ign: false, lat: 0, lng: 0};

  @Input() drawPolygon: boolean = false;

  @Input() drawPoint: boolean = false;

  @Input() drawPolyline: boolean = false;

  @Input() polygonList: {vertices: google.maps.LatLngLiteral[], polygonOptions: google.maps.PolygonOptions}[]=[];

  @Input() circleList: {center: google.maps.LatLngLiteral, radius: number, options: google.maps.CircleOptions}[]=[];

  @Input() resetMapWhenDrawingControlChange: boolean = false;

  @Output() circleRightclickEvt: EventEmitter<string> = new EventEmitter<string>();
  @Output() polygonRightclickEvt: EventEmitter<string> = new EventEmitter<string>();
  @Output() polylineRightclickEvt: EventEmitter<string> = new EventEmitter<string>();
  @Output() markerClickEvt: EventEmitter<any> = new EventEmitter<any>();

  @Output() geomMouseOver: EventEmitter<any> = new EventEmitter<any>();
  @Output() geomMouseOut: EventEmitter<any> = new EventEmitter<any>();

  // todas as geometrias criadas sao armazenadas aqui
  mapGeometryList: any[] = [];

  @ViewChildren(MapCircle) mapCircleList: QueryList<MapCircle>;

  @ViewChildren(MapPolygon) mapPolygonList: QueryList<MapPolygon>;

  @ViewChildren(MapPolyline) mapVerticesList: QueryList<MapPolyline>;

  constructor(
    public dialog: MatDialog,
    private mapDirectionsService: MapDirectionsService,
    public googleMapsApi: GoogleMapApiService,
    private loadingService: LoadingService,

  ) {
    // this.googleMapsApi.apiLoaded.subscribe({
    //   next: res => {
    //     console.log(res)
    //     if(res === false) {
    //       try {
    //         this.googleMapsApi.apiLoaded = httpClient.jsonp(
    //           'https://maps.googleapis.com/maps/api/js?key=AIzaSyDOEXfwJtJbCdfB8g0k5-aJdOHka2LMzL4&libraries=drawing',
    //           'callback'
    //         ).pipe(
    //           map(() => {
    //             this.apiLoadedEvent.emit(true);
    //             return true;
    //           }),
    //           catchError(() => {
    //             this.apiLoadedEvent.emit(false);
    //             return of(false);
    //           }),
    //         );
    //       } catch(ex) {
    //         console.log(ex);
    //       }
    //     }
    //   },
    //   error: err => console.log(err)
    // });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.mapComponentLoaded.emit(true);

      if(this.drawPoint === true || this.drawPolygon === true || this.drawPolyline === true){
        this.setDrawingManager();
        this.setCustomControl();
      }
    }, 500);

  }

  adicionarMarker(
    location: {
      lat: number,
      lng: number,
      markerOptions?: google.maps.MarkerOptions,
      dataObject?: any
    },
    veiculoRastreadoModel?: VeiculoRastreadoModel
  ) {
    this.markerPositions.push(location);

    if(veiculoRastreadoModel) {
      this.markerDataList.push(veiculoRastreadoModel);
    }
    // this.map.googleMap?.setCenter(new google.maps.LatLng(location.lat, location.lng));
    // this.map.googleMap?.setZoom(15);
    this.options = {
      center: location
    }
  }

  removerMarker(location: {lat: number, lng: number}, veiculoRastreadoModel?: VeiculoRastreadoModel) {
    const index: number = this.markerPositions.findIndex(l => l.lat == location.lat && l.lng == location.lng);
    if(index != -1){
      this.markerPositions.splice(index, 1);
    }
  }

  limparMarkers(){
    this.markerPositions = [];
    this.markerDataList = [];
  }

  computeTotalDistance(result: google.maps.DirectionsResult | null): number {
    let total = 0;
    const myroute = result?.routes[0];

    if (!myroute) {
      return 0;
    }

    for (let i = 0; i < myroute.legs.length; i++) {
      total += myroute.legs[i]!.distance!.value;
    }

    total = total / 1000;
    return total;
  }


  setDirectionsOnDrag(event: any) {
    this.rotaGerada.emit(this.directionsRenders.getDirections());
  }

  gerarRota(
    latLngOrigem: {lat: number, lng: number}, latLngDestino: {lat: number, lng: number},
    waypoints?: google.maps.DirectionsWaypoint[],
    options?: {provideRouteAlternatives: boolean },
  ) {
    this.loadingService.showLoading(true, 'Traçando rota... Por favor, aguarde!');

    // const waypoints: google.maps.DirectionsWaypoint[] = [
    //   {
    //     location: {lat: -20.839327039757876, lng: -43.725436196837634},
    //     stopover: true
    //   }, {
    //     location: {lat:-21.722827894183233, lng: -43.068263828256285},
    //     stopover: true
    //   }
    // ];

    const request: google.maps.DirectionsRequest = {
      origin: {lat: latLngOrigem.lat, lng: latLngOrigem.lng},
      destination: {lat: latLngDestino.lat, lng: latLngDestino.lng},
      travelMode: google.maps.TravelMode.DRIVING,
      provideRouteAlternatives: options ? options.provideRouteAlternatives : false,
      waypoints
    };

    this.directionsResults$ = this.mapDirectionsService.route(request).pipe(
      map((response: any) => {
        this.rotaGerada.emit(response.result);
        this.loadingService.showLoading(false);
        return response.result;
      }
    ));
  }

  updateMapCenter(lat: number, lng: number, zoom: number = 18) {
    // this.map.googleMap?.panTo({
    //   lat, lng
    // });

    // this.map.googleMap?.setZoom(zoom);

    this.markers.toArray().forEach(marker => {
      const position = marker.getPosition();
      if (position) {
        if (position.lat() === lat && position.lng() === lng) {
          this.openInfo(marker, null);
          return;
        }
      }

      const mapElement = document.querySelector('.my-map');
      if (mapElement) {
        mapElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  openInfo(marker: MapMarker, dataObject: any) {
    this.markerClickEvt.emit(dataObject);
    const title: number | null = Number(marker.getTitle());
    this.dadosMarker.placa = this.markerDataList[title].placa;
    this.dadosMarker.velocidade = this.markerDataList[title].ultimaVelocidade;
    this.dadosMarker.ign = this.markerDataList[title].alertas.ignicao;
    this.dadosMarker.lat = this.markerDataList[title].ultimaLatitude;
    this.dadosMarker.lng = this.markerDataList[title].ultimaLongitude;
    this.zoom = 12;
    this.info.open(marker);
  }

  setCustomControl(): void {
    const me = this;
    // Create the DIV to hold the control.
    const centerControlDiv = document.createElement('div');

    const controlButton = document.createElement('button');

    // Set CSS for the control.
    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = '2px solid #fff';
    controlButton.style.borderRadius = '3px';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.color = 'rgb(25,25,25)';
    controlButton.style.cursor = 'pointer';
    controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
    // controlButton.style.fontSize = '16px';
    controlButton.style.lineHeight = '21px';
    controlButton.style.margin = '5px 0 22px';
    // controlButton.style.padding = '0 5px';
    controlButton.style.textAlign = 'center';

    controlButton.textContent = 'Limpar';
    controlButton.title = 'Click to recenter the map';
    controlButton.type = 'button';

    // Setup the click event listeners: simply set the map to Chicago.
    controlButton.addEventListener('click', () => {
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        width: 'auto',
        data: {
          titulo: 'Atenção',
          subtitulo: `Tem certeza que deseja remover todas as geometrias do mapa?`}
      });

      dialogRef.afterClosed().subscribe(resultado => {
        if(resultado === 'true') {
          this.clearMap();
        }
      });
    });

    // Append the control to the DIV.
    centerControlDiv.appendChild(controlButton);

    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
  }

  private getDrawingModes() {
    const drawingModes = [];
    if(this.drawPoint == true) {
      drawingModes.push(google.maps.drawing.OverlayType.CIRCLE);
    }

    if(this.drawPolygon == true) {
      drawingModes.push(google.maps.drawing.OverlayType.POLYGON);
    }

    if(this.drawPolyline == true) {
      drawingModes.push(google.maps.drawing.OverlayType.POLYLINE);
    }

    return drawingModes;
  }

  setDrawingManager(): void {
    const me = this;
    if(this.drawPolygon === true) {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        // drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: me.getDrawingModes()
        },
        circleOptions: {
          clickable: true,
          editable: true,
          draggable: true
        },
        polygonOptions: {
          clickable: true,
          editable: true,
          draggable: true
        },
        polylineOptions: {
          clickable: true,
          editable: true,
          draggable: true
        }
      });

      this.drawingManager.setMap(this.map.googleMap || null);

      google.maps.event.addListener(this.drawingManager, "drawingmode_changed", function() {
        if(me.mapGeometryList.length > 0  && me.resetMapWhenDrawingControlChange == true) {
          me.clearMap();
        }
      });

      google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event: any) => {
        event.id = new Date().getMilliseconds();

        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          this.polygonCreatedEvent.emit(event.overlay.getPath().getArray());
        } else if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
          this.circleCreatedEvent.emit({coordinates: [event.overlay.getCenter()], radius: event.overlay.radius});
        } else if (event.type === google.maps.drawing.OverlayType.POLYLINE) {
          this.polylineCreatedEvent.emit(event.overlay.getPath().getArray());
        }

        event.overlay.addListener('mouseover', function(evt: any) {
          // me.raio = event.overlay.radius;
          // me.showDadosCirculo = true;
          // console.log(me.showDadosCirculo);
          me.geomMouseOver.emit(event);
        });
        event.overlay.addListener('mouseout', function(evt: any) {
          // me.raio = event.overlay.radius;
          // me.showDadosCirculo = false;
          // console.log(me.showDadosCirculo);
          me.geomMouseOut.emit(event);
        });

        event.overlay.addListener('rightclick', function(evt: any) {
          event.overlay.setMap();
        });

        this.mapGeometryList.push(event);
      });
    }
  }

  clearMap(): void {
    this.mapGeometryList.forEach((geom:any) => {
      geom.overlay.setMap(null);
    });
    this.mapGeometryList = [];
    this.polygonCreatedEvent.emit(null);
    this.circleCreatedEvent.emit(null);
    this.polylineCreatedEvent.emit(null);
  }

  circleRightclick(circle: any) {
    this.circleRightclickEvt.emit(circle._id);
  }

  polygonRightclick(polygon: any) {
    this.polygonRightclickEvt.emit(polygon._id);
  }

  polylineRightclick(polyline: any) {
    this.polylineRightclickEvt.emit(polyline._id);
  }

  setCenter(location: { lat: number, lng: number }) {
    if (this.map && this.map.googleMap) {
      if (location.lat && location.lng) {
        const latLng = new google.maps.LatLng(location.lat, location.lng);
        this.map.googleMap.setCenter(latLng);
      }
    }
  }
}
